/* eslint-disable import/no-named-default */
// TODO CS - ^ figure out how to import the same name from
// two components as default exports, then disabiguate here.
// Linter complains if you don't use a default export from the component.
// And here the linter complains when you rename the default.

const AUTH_REQUIRES_USER = 'user';
const NO_AUTH_REQUIRED = 'no-auth';
const AUTH_REQUIRES_PARTNER = 'partner';

interface Route {
  path: string;
  auth: string;
  showSidebarIfLoggedIn?: boolean;
}

/**
 * NEW ROUTES GO HERE, but also in the respective file for
 * optimal code-splitting:
 * - InternalUserRoutes
 * - InternalPartnerRoutes
 * - PublicRoutes
 */
export const routes: Route[] = [
  {
    path: '/about',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/author-resources',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/author-resources/running-successful-promotions-any-genre',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/author-resources/running-successful-romance-promotions',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/author-resources/running-successful-fantasy-promotions',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/author-resources/running-successful-thriller-promotions',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/authors/:id/:name',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/ai/book-character-generator',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/ai/book-plot-generator',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/ai/book-title-generator',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/ai/book-description-generator',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/ai/pen-name-generator',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/best-book-promotion-sites',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/best-book-promotion-sites/:id/:name',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/books/:id/:name',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/categories/:id/:name',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/contact',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/ebook-deals/:freeOnly?',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: false,
  },
  {
    path: '/partners-referral-program',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/partners/account',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/author-profiles/:id/edit',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/books/new',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/books/:id/edit',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/books/:id',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/books',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/dashboard',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/password/forgot',
    auth: NO_AUTH_REQUIRED,
  },
  {
    path: '/partners/password/reset',
    auth: NO_AUTH_REQUIRED,
  },
  {
    path: '/partners/sign-in',
    auth: NO_AUTH_REQUIRED,
  },
  {
    path: '/partners/sign-up',
    auth: NO_AUTH_REQUIRED,
  },
  {
    path: '/partners/unsubscribe-recapture-email',
    auth: NO_AUTH_REQUIRED,
  },
  {
    path: '/partners/unsubscribe-reminder-email',
    auth: NO_AUTH_REQUIRED,
  },
  {
    path: '/partners/author-profiles',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/author-profiles/new',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/promotions/:id/edit',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/promotions/new',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partners/promotions',
    auth: AUTH_REQUIRES_PARTNER,
  },
  {
    path: '/partnership-guidelines',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/pricing',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/privacy',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/products/best-e-readers-2023',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/promotion-requirements',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/readers/:id/edit',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: false,
  },
  {
    path: '/subscribers-and-statistics',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: false,
  },
  {
    path: '/terms-and-conditions',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/users/ai',
    auth: AUTH_REQUIRES_USER,
    showSidebarIfLoggedIn: true,
  },
  {
    path: '/users/books/:id',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/books',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/books/:id/edit',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/discounts/new',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/discounts',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/discounts/:id/edit',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/newsletters/:id',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/newsletters',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/promotions/:id/edit',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/promotions',
    auth: AUTH_REQUIRES_USER,
  },
  {
    path: '/users/sign-in',
    auth: NO_AUTH_REQUIRED,
  },
  // Similarly, this route has to appear after other
  // more specific routes.
  {
    path: '/partners',
    auth: NO_AUTH_REQUIRED,
    showSidebarIfLoggedIn: false,
  },
  // This homepage route has to be last, or it will
  // be selected as the main route to check.
  {
    path: '/',
    auth: NO_AUTH_REQUIRED,
  },
];

/**
 * @returns array of paths requiring user authentication
 */
const pathsRequiringUser = () =>
  routes
    .filter((route) => route.auth === AUTH_REQUIRES_USER)
    .map((route) => route.path);

/**
 * @returns array of paths requiring user authentication
 */
const pathsRequiringPartner = () =>
  routes
    .filter((route) => route.auth === AUTH_REQUIRES_PARTNER)
    .map((route) => route.path);

/**
 * @returns array of paths where the sidebar should be shown
 * if there is a partner/user logged in.
 */
const pathsToShowSidebarIfLoggedIn = () =>
  [
    ...routes.filter((route) => route.showSidebarIfLoggedIn),
    ...routes.filter((route) => route.auth === AUTH_REQUIRES_USER),
    ...routes.filter((route) => route.auth === AUTH_REQUIRES_PARTNER),
  ].map((route) => route.path);

// Strip the slash from the end of the path, so we can compare
// slashed paths with unslashed paths equivalently
export const slashStrippedPath = (path: string) => {
  if (path.substring(path.length - 1) === '/') {
    return path.substring(0, path.length - 1);
  }
  return path;
};

/**
 * We replace any segment of numbers with `:id`, in order
 * to match the generic routes defined above.
 *
 * E.g.
 * original: /partners/books/12
 * replaced: /partners/books/:id
 */
const replacePathNumberWithId = (path: string): string =>
  path.replace(/\/[0-9]+/g, '/:id');
/**
 * Check if the path requires a partner to be signed in.
 */
export const requiresPartnerAuth = (path: string): boolean => {
  const pathWithId = replacePathNumberWithId(slashStrippedPath(path));
  return pathsRequiringPartner().includes(pathWithId);
};

/**
 * Same as above, for the user
 */
export const requiresUserAuth = (path: string): boolean => {
  const pathWithId = replacePathNumberWithId(slashStrippedPath(path));
  return pathsRequiringUser().includes(pathWithId);
};

export const showWithSidebarIfAvailable = (path: string): boolean =>
  pathsToShowSidebarIfLoggedIn().includes(
    replacePathNumberWithId(slashStrippedPath(path))
  );
