/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import {
  checkStringPayload,
  handleErrors,
  addSnack,
} from '../snacks/snacksSlice';
import { Partner } from '../books/booksSlice';

export interface PromotionApproval {
  id: number;
  promotion_date: string;
}

export interface PercentileResponse {
  promotion_id: number;
  percentile: number;
  count: number;
  clicks: number;
}

export interface PromotionRejection {
  id: number;
  rejection_reason: string | null;
  email_to_send: string;
  rejection_details: string;
}

export interface PromotionCancellation {
  id: number;
  email_to_send: string;
  cancellation_details: string;
}

export interface ApprovedPromotionCancellation {
  id: number;
  resourceType: string;
}

export interface FeedbackRequest {
  id: number;
  email_to_send: string;
  feedback_details: string;
}

export interface StripeError {
  id: number;
  error_type: string;
  error_code: string;
  error_message: string;
  created_at: string;
}

export interface NewPromotion {
  id?: number;
  book_id: number;
  category_id: number;
  sale_price: number;
  current_price: number;
  requested_date: string;
  date_flexibility: string;
  resourceType: string;
  discount_code?: string;
  stripe_card_token?: string | null;
}

export interface EditPromotion {
  id?: number;
  category_id: number;
  sale_price: number;
  current_price: number;
  requested_date?: string;
  promotion_date?: string;
  date_flexibility: string;
  resourceType: string;
}

export interface Discount {
  id: number;
  amount_off_in_cents: number;
  code: string;
  discount_uses: number;
  percent_off: number;
}

export interface Promotion {
  id: number;
  book_id: number;
  partner_id?: number;
  partner?: Partner | null;
  book?: any; // todo cs
  click_logs?: Array<{}> | null;
  stripe_errors: Array<StripeError> | null;
  category: any;
  category_id?: number;
  created_at: string;
  status: string;
  sale_price: number;
  rejection_details: string | null;
  cancellation_details: string | null;
  feedback_details: string | null;
  current_price: number;
  amount_due?: number;
  referral_credits_applied: number;
  requested_date: string;
  reviewed_by?: {
    email?: string;
  };
  user_promotion?: boolean;
  rejection_reason: string | null;
  promotion_date: string | null;
  reviewed_at: string | null;
  date_flexibility: string;
  amazon_click_counter: number;
  ibooks_click_counter: number;
  kobo_click_counter: number;
  google_play_click_counter: number;
  barnes_and_noble_click_counter: number;
  discount_used_in_cents?: number;
  discount?: Discount;
}

interface GenericApiParams {
  joins?: Array<any>;
  sort_col?: string;
  sort_dir?: string;
  page?: number;
  per?: number;
  max_price?: number;
}

export interface PromotionParams extends GenericApiParams {
  id?: number;
  partner?: boolean;
  status?: Array<string>;
  category_id?: Array<number>;
  book_id?: number;
  referral_credits_applied?: boolean;
  resourceType: string;
  storeAction?: string;
}

/**
 * Because we store reviewed and unreviewed promotions which
 * appear in separate lists, we have to sort based on reviewed_at
 * when they have been reviewed, and otherwise sort on requested_date.
 *
 * TODO CS - the sorting strategy depends on the request being made.
 * For example, the "Last 20 reviewed promotions" for users should
 * be sorted by reviewed_at DESC
 * By contrast, the partner's past promotions should be sorted by
 * the promotion date if it exists, or the requested date if the promotion
 * date wasn't set. It should have no notion of the requeted_at.
 * This divergence in strategies is accommodated for in our current architecture.
 */
const promotionsAdapter = createEntityAdapter<Promotion>();

export const getAllPromotions = createAsyncThunk<
  void, // Return type of the payload creator
  PromotionParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotions/get', async (params: PromotionParams, thunkApi: any) => {
  const headers = { 'resource-type': params.resourceType };

  try {
    const response = await axiosInstance.get('/api/v1/promotions', {
      headers,
      params,
    });

    if (params.storeAction && params.storeAction === 'Add') {
      thunkApi.dispatch(
        promotionsSlice.actions.promotionsReceivedAdd(response.data.promotions)
      );
    } else {
      thunkApi.dispatch(
        promotionsSlice.actions.promotionsReceivedReplace(
          response.data.promotions
        )
      );
    }
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getById = createAsyncThunk<
  void, // Return type of the payload creator
  PromotionParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotions/get', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/promotions/${params.id}`,
      {
        headers: { 'resource-type': params.resourceType },
        params: {
          joins: params.joins,
        },
      }
    );
    thunkApi.dispatch(promotionsSlice.actions.promotionReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getLastPromotionPercentile = createAsyncThunk<
  PercentileResponse, // Return type of the payload creator
  number, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotions/get', async (promotionId, thunkApi: any) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/promotions/${promotionId}/last-promotion-percentile`,
      {
        headers: { 'resource-type': 'user' },
      }
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    // no handleErrors here, since we want to react
    // more accurately to various error messages for
    // display in the interface.
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const createPromotion = createAsyncThunk<
  void, // Return type of the payload creator
  NewPromotion, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotions/create', async (promotion: NewPromotion, thunkApi: any) => {
  const headers = { 'resource-type': promotion.resourceType };

  try {
    const response = await axiosInstance.post('/api/v1/promotions', promotion, {
      headers,
    });
    thunkApi.dispatch(promotionsSlice.actions.promotionReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, promotion.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const updatePromotion = createAsyncThunk<
  void,
  EditPromotion,
  { rejectValue: Error }
>('promotions/update', async (promotion: EditPromotion, thunkApi: any) => {
  const headers = { 'resource-type': promotion.resourceType };

  try {
    const response = await axiosInstance.patch(
      `/api/v1/promotions/${promotion.id}/`,
      promotion,
      {
        headers,
        params: {
          joins: ['category'],
        },
      }
    );
    thunkApi.dispatch(promotionsSlice.actions.promotionReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, promotion.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const approvePromotion = createAsyncThunk<
  void, // Return type of the payload creator
  PromotionApproval, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotions/approve', async (promotion: PromotionApproval, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.post(
      `/api/v1/promotions/${promotion.id}/approve`,
      promotion,
      {
        headers,
      }
    );
    thunkApi.dispatch(promotionsSlice.actions.promotionChanged(promotion.id));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const rejectPromotion = createAsyncThunk<
  void, // Return type of the payload creator
  PromotionRejection, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotions/reject', async (promotion: PromotionRejection, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.post(
      `/api/v1/promotions/${promotion.id}/reject`,
      promotion,
      {
        headers,
      }
    );
    thunkApi.dispatch(promotionsSlice.actions.promotionChanged(promotion.id));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const cancelPromotion = createAsyncThunk<
  void, // Return type of the payload creator
  PromotionCancellation, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>(
  'promotions/cancel',
  async (promotion: PromotionCancellation, thunkApi: any) => {
    const headers = { 'resource-type': 'user' };

    try {
      const response = await axiosInstance.post(
        `/api/v1/promotions/${promotion.id}/cancel`,
        promotion,
        {
          headers,
        }
      );
      thunkApi.dispatch(promotionsSlice.actions.promotionChanged(promotion.id));
      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      handleErrors(error, thunkApi.dispatch, 'user');
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

export const cancelApprovedPromotion = createAsyncThunk<
  void, // Return type of the payload creator
  ApprovedPromotionCancellation, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>(
  'promotions/cancelApproved',
  async (promotion: ApprovedPromotionCancellation, thunkApi: any) => {
    try {
      const response = await axiosInstance.post(
        `/api/v1/promotions/${promotion.id}/cancel_approved`,
        {},
        {
          headers: { 'resource-type': promotion.resourceType },
          params: {
            joins: [
              'book',
              'category',
              'click_logs',
              'discount',
              'partner',
              'stripe_errors',
            ],
          },
        }
      );

      thunkApi.dispatch(
        promotionsSlice.actions.promotionReceived(response.data)
      );

      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      handleErrors(error, thunkApi.dispatch, promotion.resourceType);
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

export const chargePromotion = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>(
  'promotions/charge',
  async (
    params: {
      resourceType: string;
      id: number;
      joins: [];
    },
    thunkApi: any
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/v1/promotions/${params.id}/charge`,
        params,
        { headers: { 'resource-type': params.resourceType } }
      );
      thunkApi.dispatch(
        promotionsSlice.actions.promotionReceived(response.data)
      );
      thunkApi.dispatch(
        addSnack({ message: 'The charge was successful.', variant: 'success' })
      );
      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      handleErrors(error, thunkApi.dispatch, params.resourceType);
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

export const sendFeedback = createAsyncThunk<
  void, // Return type of the payload creator
  FeedbackRequest, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>(
  'promotions/sendFeedback',
  async (promotion: FeedbackRequest, thunkApi: any) => {
    const headers = { 'resource-type': 'user' };

    try {
      const response = await axiosInstance.post(
        `/api/v1/promotions/${promotion.id}/partner_feedback`,
        promotion,
        {
          headers,
        }
      );
      thunkApi.dispatch(promotionsSlice.actions.promotionChanged(promotion.id));
      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      handleErrors(error, thunkApi.dispatch, 'user');
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/** ******* MAIN SLICE ******* */
export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState: promotionsAdapter.getInitialState({}),
  reducers: {
    /**
     * A promotion was changed, so remove it from the list.
     *
     * Whichever component loads next will grab it.
     * This is required to ensure that navigating back to the list of
     * promotions doesn't still show the promotion whose status changed.
     */
    promotionChanged: (state, action: PayloadAction<number>) => {
      promotionsAdapter.removeOne(state, action);
    },
    promotionsReceivedReplace: (state, action: PayloadAction<Promotion[]>) => {
      checkStringPayload(action.payload);
      promotionsAdapter.setAll(state, action.payload);
    },
    promotionsReceivedAdd: (state, action: PayloadAction<Promotion[]>) => {
      checkStringPayload(action.payload);
      promotionsAdapter.upsertMany(state, action.payload);
    },
    promotionReceived: (state, action: PayloadAction<Promotion>) => {
      promotionsAdapter.upsertOne(state, action.payload);
    },
    removeAllPromotions: (state) => {
      promotionsAdapter.removeAll(state);
    },
  },
});

// Other actions can appear here, such as:
// https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions

const selectors = promotionsAdapter.getSelectors<RootState>(
  (state) => state.promotions
);
export const {
  selectAll: selectAllPromotions,
  selectById: selectPromotionById,
} = selectors;

export default promotionsSlice.reducer;
